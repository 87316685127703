const shoppingBasketSelector = (state: AppState) => state.basket || null;

export const shoppingBasketLoadingSelector = (state: AppState) => {
  const basket = shoppingBasketSelector(state);
  if (!basket.isLoading) return false;

  return basket.isLoading;
};

export const shoppingBasketItemsSelector = (state: AppState) => {
  const basket = shoppingBasketSelector(state);
  if (!basket.items) return null;

  return basket.items;
};

export const shoppingBasketCountSelector = (state: AppState) => {
  const basket = shoppingBasketSelector(state);

  return basket.count;
};
