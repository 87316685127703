/**
 * Webshop Maintenance
 */
export const webshopMaintenancePageSelector = (state: AppState) => {
  if (!state.maintenance) return null;

  return state.maintenance.content;
};

/**
 * Maintenance loading
 */
export const webshopMaintenanceLoadingSelector = (state: AppState) => state.maintenance.loading;

/**
 * Webshop Maintenance Enabled
 */
export const webshopMaintenanceEnabledSelector = (state: AppState) => {
  const maintenance = webshopMaintenancePageSelector(state);
  const isLoading = webshopMaintenanceLoadingSelector(state);

  if (isLoading) return false;
  if (!maintenance) return null;

  return maintenance.maintenance;
};
