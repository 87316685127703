import axios from 'axios';

let interceptorInstance: number | null = null;
const interceptorRegistry = {};

/**
 * Remove axios interceptor
 */
function removeInterceptors() {
  if (interceptorInstance) {
    axios.interceptors.request.eject(interceptorInstance);
  }
}

/**
 * Add a new interception handler by name
 * @param {string} name
 * @param {*} handler
 */
export function addInterceptor(name, handler) {
  interceptorRegistry[name] = handler;
  renewInterceptors();
}

/**
 * Refresh axios interceptor with current handlers.
 */
function renewInterceptors() {
  removeInterceptors();
  interceptorInstance = axios.interceptors.request.use(async (config) => {
    const registryItems = Object.keys(interceptorRegistry);
    for (const registryItem of registryItems) {
      config = await interceptorRegistry[registryItem](config);
    }
    return config;
  });
}

/**
 * Add new header to all request methods
 *
 * @param {*} config
 * @param {string} key
 * @param {*} value
 */
export function addToAllInterceptorMethods(config, key, value) {
  config.headers.common[key] = value;
  config.headers.post[key] = value;
  config.headers.get[key] = value;
  config.headers.put[key] = value;
  config.headers.patch[key] = value;
  config.headers.delete[key] = value;

  return config;
}
