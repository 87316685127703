import { useEffect } from 'react';

export function useBodyScroll(shouldNoScroll: boolean) {
  useEffect(() => {
    if (shouldNoScroll) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    }

    return () => {
      document.body.style.overflow = 'initial';
      document.body.style.touchAction = 'initial';
    };
  }, [shouldNoScroll]);
}
