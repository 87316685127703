import { serialize } from 'cookie';

export const CookieStorage = {
  getItem(key: string): string | null {
    return (
      document.cookie.split('; ').reduce((acc, c) => {
        const [key, ...value] = c.split('=');
        acc[key] = value.join('=');
        return acc;
      }, {})[key] || null
    );
  },
  setItem(key: string, value: string, expires?: Date) {
    document.cookie = serialize(key, value, { path: '/', expires, secure: true });
  },
  removeItem(key: string) {
    document.cookie = serialize(key, '', { path: '/', expires: new Date(0), secure: true });
  },
};
