import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import getConfig from '../../config';
import { globalsServerSelector } from '../selectors/globalsSelectors';

export type getEndpointType = (endpoint: string, path: string) => string;

export function useGetEndpoint(): { getEndpoint: getEndpointType } {
  const server = useSelector(globalsServerSelector);
  const getEndpoint = useCallback(
    (endpoint: string, path = '') => {
      if (!server) return path;

      const appConfig = getConfig(server);

      if (!appConfig.endpoints[endpoint]) {
        return path;
      }

      return appConfig.endpoints[endpoint].path + path;
    },
    [server],
  );

  return { getEndpoint };
}
